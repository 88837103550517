// npm
import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Grid, Typography, useTheme } from '@mui/material'
import { SwiperProps, SwiperSlide } from 'swiper/react'
// src
import Divider from '@atoms/layout/Divider'
import Motif from '@atoms/svg/Motif'
import MainHeading, { MainHeadingProps } from '@molecules/headings/MainHeading'
import Subtitle from '@molecules/headings/Subtitle'
import HorizontalSwiper from '@organisms/swipers/layouts/HorizontalSwiper'

interface Props {
  keyId: string
  subtitle: string
  mainHeading: string
  text?: string
  slides: Queries.DatoCmsHorizontalSlide[]
}

const ContentSectionSwiper = ({
  keyId,
  subtitle,
  mainHeading,
  text,
  slides,
}: Props) => {
  const theme = useTheme()

  const swiperStyles = {
    ['.swiper-extra']: {
      marginTop: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(8),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
      },
      [theme.breakpoints.up('xl')]: {
        marginTop: theme.spacing(10),
        paddingLeft: theme.spacing(20),
        paddingRight: theme.spacing(20),
      },
      background: `linear-gradient(0deg, ${theme.palette.primary.light} 50%, ${theme.palette.secondary.main} 50%)`,
      ['.swiper-slide']: {
        color: theme.palette.text.primary,
        [theme.breakpoints.up('md')]: {
          transform: 'scale(0.8)',
          transition: theme.transitions.create(['transform']),
          ['&.swiper-slide-active']: {
            transform: 'scale(1)',
          },
        },
      },
    },
  }

  const swiperProps: SwiperProps = {
    slidesPerView: 1,
    spaceBetween: 0,
    breakpoints: {
      [theme.breakpoints.values.xs]: {
        spaceBetween: 0,
      },
      [theme.breakpoints.values.md]: {
        spaceBetween: -20,
      },
      [theme.breakpoints.values.lg]: {
        spaceBetween: -50,
      },
      [theme.breakpoints.values.xl]: {
        spaceBetween: -100,
      },
    },
  }

  const mainHeadingProps: MainHeadingProps = {
    typographyProps: {
      variant: 'h2',
      color: 'white',
      textAlign: 'center',
      lineHeight: '1',
      mb: 0,
      mt: 3,
    },
    lineBreaksProps: {
      keyId: keyId,
      text: mainHeading,
      breakpoint: 'xs',
    },
  }

  return (
    <Box
      pt={{
        xs: 4,
        lg: 12,
      }}
      position="relative"
      bgcolor="secondary.main"
      color="white"
      sx={{
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.primary.light,
        borderBottomWidth: {
          xs: theme.spacing(4),
          xl: theme.spacing(12),
        },
      }}
    >
      <Container maxWidth="md">
        <Subtitle
          text={subtitle}
          borderType="bottom"
          typographyProps={{ component: 'h2' }}
        />
        <MainHeading {...mainHeadingProps} />
      </Container>
      <Container maxWidth="sm">
        <Box
          component="div"
          sx={{
            mt: 1,
            mb: 4,
            textAlign: 'center',
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <Motif />
      </Container>
      <HorizontalSwiper
        wrapperProps={{ swiperStyles: swiperStyles }}
        swiperProps={swiperProps}
      >
        {slides.map((slide: Queries.DatoCmsHorizontalSlide, index: number) => {
          return (
            <SwiperSlide key={`${index}-${slide.id}`}>
              <Container
                maxWidth={false}
                sx={{
                  px: {
                    lg: 0,
                  },
                }}
              >
                <Box
                  className="slide-outer-container"
                  pl={{
                    xl: 8,
                  }}
                >
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid
                      item
                      lg={9}
                      sx={{
                        mx: {
                          xs: '-10px',
                          sm: '-20px',
                          lg: 0,
                        },
                        position: 'relative',
                        zIndex: 2,
                        ['@media not all and (min-resolution:.001dpcm)']: {
                          mx: '0 !important',
                        },
                      }}
                    >
                      <GatsbyImage image={slide.image.gatsbyImageData} alt="" />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={3}
                      sx={{
                        position: 'relative',
                        zIndex: 10,
                        background: 'white',
                      }}
                    >
                      <Box
                        bgcolor="white"
                        sx={{
                          mt: {
                            xs: '-40px',
                            lg: 0,
                          },
                          ml: {
                            lg: '-50%',
                          },
                          position: 'relative',
                          zIndex: 11,
                          px: {
                            xs: 3,
                            md: 5,
                            lg: 7,
                            xl: 9,
                          },
                          pt: {
                            xs: 3,
                            lg: 5,
                          },
                          pb: {
                            xs: 4,
                            lg: 8,
                            xl: 10,
                          },
                          ['@media not all and (min-resolution:.001dpcm)']: {
                            mt: '0 !important',
                          },
                        }}
                      >
                        <Typography
                          component="div"
                          pb={{ xs: 2, lg: 4, xl: 5 }}
                          ml={{ sx: 0, lg: -2, xl: -3 }}
                          color="primary.main"
                          fontFamily="fonts.serif"
                          variant="body2"
                        >
                          {(index + 1).toLocaleString('en-GB', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                          .
                        </Typography>
                        <MainHeading
                          typographyProps={{
                            variant: 'h3',
                            sx: {
                              lineHeight: '1',
                              mb: 0,
                            },
                          }}
                          lineBreaksProps={{
                            text: slide.headingNode.childMarkdownRemark.html,
                            keyId: slide.id,
                            breakpoint: 'lg',
                          }}
                        />
                        <Divider
                          sx={{
                            my: 1,
                          }}
                        />
                        <Box
                          mt={1}
                          mb={1}
                          dangerouslySetInnerHTML={{
                            __html: slide.textNode.childMarkdownRemark.html,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </SwiperSlide>
          )
        })}
      </HorizontalSwiper>
    </Box>
  )
}

export default memo(ContentSectionSwiper)
